import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

// MUI
import { Box, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FavoriteIcon from '@mui/icons-material/Favorite';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(2),
    borderRadius: '32px 32px 0 0',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2, 4),
      borderRadius: '64px 64px 0 0',
    },
  },
  fontBold: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const Footer: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      {/* Line One */}
      <Box display="flex" flexWrap="wrap" justifyContent="center">
        <Box display="flex" alignItems="center" mb={1}>
          <Typography align="center" color="textSecondary" variant="body2">
            Made with
          </Typography>
          <Box mx={0.5}>
            <FavoriteIcon fontSize="inherit" htmlColor="#ce1f6a" />
          </Box>
          <Typography align="center" color="textSecondary" variant="body2">
            by Prayoga LLC
          </Typography>
        </Box>
        <Box mx={2} px={2} borderLeft={{ md: '1px solid grey' }} mb={1}>
          <Typography align="center" color="textSecondary" variant="body2">
            Please email us at{' '}
            <Link
              href="mailto:feedback@campsitemonitor.com"
              target="_blank"
              underline="hover"
              rel="noreferrer"
            >
              feedback@campsitemonitor.com
            </Link>{' '}
            for any feedback!
          </Typography>
        </Box>
      </Box>
      {/* Line Two */}
      <Box display="flex" flexWrap="wrap" justifyContent="center">
        <Typography align="center" color="textSecondary" variant="body2">
          © Prayoga LLC 2025. All rights reserved.
        </Typography>
        <Box
          mx={2}
          px={2}
          borderRight={{ sm: '1px solid grey' }}
          borderLeft={{ sm: '1px solid grey' }}
        >
          <Typography align="center" variant="body2">
            <Link component={RouterLink} to="/privacy-policy" underline="hover">
              Privacy Policy
            </Link>
          </Typography>
        </Box>
        <Typography align="center" variant="body2">
          <Link component={RouterLink} to="/terms" underline="hover">
            Terms &amp; Conditions
          </Link>
        </Typography>
      </Box>
    </div>
  );
};

export default React.memo(Footer);
